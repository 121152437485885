import { debounce } from "@solid-primitives/scheduled";
import { cache, createAsync } from "@solidjs/router";
import {
  Accessor,
  createMemo,
  createSignal,
  For,
  Show,
  Suspense,
} from "solid-js";
import { placeholderCard } from "~/assets/assets";
import { useModal } from "~/components/modal";
import { DenominationDetailsModal } from "~/components/procurement/denominations_details_modal";
import { useProcurement } from "~/context/procurement";
import { getProductSearch } from "~/server/apis/client_apis";
import { Search } from "~/components/search/search";
import {
  CatalogueRouteData,
  getCatalogueRouteData,
} from "~/server/data/catalogue_route_data";
import { Product } from "~/server/types/search";
import HubbleImage from "~/widgets/hubble_image";
import InfiniteScroll from "~/widgets/infinite_scroll";
import { DottedLoader } from "~/widgets/loader";
import { Spacer } from "~/widgets/spacer";

const getCatalogueRouteData$C = cache(getCatalogueRouteData, "catalogue");

function PopularBrands(props: {
  products?: Product[];
  onBrandClick: ({
    brandId,
  }: {
    brandId: string;
    discountPercentage: number;
  }) => void;
}) {
  return (
    <div class="mb-4 mt-8 flex flex-col gap-3">
      <div class="flex items-center gap-3 px-5">
        <p class="text-nowrap text-subtitleSmall text-textDark">
          Popular brands
        </p>
        <hr class="h-px w-full bg-baseTertiaryMedium" />
      </div>

      <Show when={props.products?.length}>
        <div
          style={{
            "grid-template-columns": `repeat(${Math.ceil(props.products!.length / 2)}, minmax(80px, 1fr))`,
          }}
          class={`grid grid-flow-row grid-rows-2 gap-x-5 gap-y-3 px-5 pb-3`}
        >
          <For each={props.products}>
            {(product) => (
              <div
                class="flex cursor-pointer flex-col items-center"
                onClick={() =>
                  props.onBrandClick({
                    brandId: product?.id,
                    discountPercentage:
                      product.voucherProduct.discountPercentage,
                  })
                }
              >
                <HubbleImage
                  class="h-20 w-20 rounded-3xl"
                  src={
                    "https://app.myhubble.money/static/brand-assets/" +
                    product.brandKey +
                    "/top_brand.png"
                  }
                  alt={product.voucherProduct.title}
                  errorImage={placeholderCard}
                />
                <Show when={product.voucherProduct.discountPercentage}>
                  <div class="mt-2.5 text-f12 text-textNormal">
                    {product.voucherProduct.discountPercentage}% off
                  </div>
                </Show>
              </div>
            )}
          </For>
        </div>
      </Show>
    </div>
  );
}

export default function Catalogue() {
  const routeData: Accessor<CatalogueRouteData | undefined> =
    createAsync<CatalogueRouteData>(() => {
      return getCatalogueRouteData$C();
    });

  const { openModal } = useModal()!;
  const { procurement, addToCart } = useProcurement();

  const [page, setPage] = createSignal(0);
  const [hasMore, setHasMore] = createSignal(true);
  const [products, setProducts] = createSignal(
    procurement?.allBrands?.data?.[0]?.products || []
  );

  const fetchNextPage = async () => {
    try {
      const newPage = page() + 1;
      const result = await getProductSearch("", {
        limit: 20,
        pageNo: newPage,
      });

      setPage(newPage);

      if (!result.nextCursor) {
        setHasMore(false);
      }

      setProducts((prev) => [...prev, ...result.data[0].products]);
    } catch (error) {
      console.error("Failed to fetch products:", error);
      setHasMore(false);
    }
  };

  const openDenominationModal = ({
    brandId,
    discountPercentage,
  }: {
    brandId: string;
    discountPercentage: number;
  }) => {
    openModal(() => {
      return (
        <DenominationDetailsModal
          brandId={brandId}
          discountPercentage={discountPercentage}
          balance={procurement.balance}
          addToCart={addToCart}
        />
      );
    }, "md:w-[360px] md:h-[480px] h-fit rounded-[20px]");
  };

  const debouncedFetchNextPage = debounce(fetchNextPage, 300);
  const [isSearchActive, setIsSearchActive] = createSignal(false);
  const [clearSearch, setClearSearch] = createSignal(false);

  return (
    <Suspense
      fallback={
        <div class="flex-1 items-center justify-center">
          <DottedLoader color="#999" />
        </div>
      }
    >
      <div class="noScrollbar flex-1 overflow-y-auto">
        <div
          class={`fixed top-0 z-10 flex w-[900px] min-w-[62%] border-b border-baseTertiaryMedium bg-white px-5 py-[10px] `}
        >
          <Search
            setIsSearchActive={setIsSearchActive}
            setClearSearch={setClearSearch}
            clearSearch={clearSearch}
            isSearchActive={isSearchActive}
            isProcurment={true}
            allBrands={products}
            openDenominationModal={(product: Product) => {
              openModal(() => {
                return (
                  <DenominationDetailsModal
                    balance={procurement.balance}
                    brandId={product.id}
                    addToCart={addToCart}
                    discountPercentage={
                      product.voucherProduct.discountPercentage
                    }
                  />
                );
              }, "md:w-[360px] md:h-[480px] h-fit rounded-[20px]");
            }}
          />
        </div>
        <Spacer height={54} />
        <PopularBrands
          products={routeData()?.popularBrands}
          onBrandClick={openDenominationModal}
        />
        <div class="mb-8 mt-4">
          <div class="flex items-center gap-3 px-5">
            <p class="text-nowrap text-subtitleSmall text-textDark">
              All brands
            </p>
            <hr class="h-px w-full bg-baseTertiaryMedium" />
          </div>
          <div class="mt-5 grid grid-cols-3 gap-6 gap-x-3 gap-y-6 overflow-auto px-4 md:grid-cols-5 md:gap-x-8 lg:grid-cols-6">
            <InfiniteScroll hasMore={hasMore()} next={debouncedFetchNextPage}>
              <For each={products()}>
                {(brand) => (
                  <div
                    class={`group flex cursor-pointer flex-col gap-2`}
                    onClick={() =>
                      openDenominationModal({
                        brandId: brand.id,
                        discountPercentage:
                          brand.voucherProduct?.discountPercentage,
                      })
                    }
                  >
                    <HubbleImage
                      src={brand.voucherProduct?.iconImageUrl}
                      class="inline-block aspect-[53/59] h-fit w-full rounded-2xl"
                      alt={brand.voucherProduct?.title + " logo image"}
                      errorImage={placeholderCard}
                    />
                    <div class="flex w-full flex-col px-1">
                      <div class="flex flex-col gap-[0.5]">
                        <span
                          class={`line-clamp-2 text-subtitleSmall text-textDark `}
                        >
                          {brand.voucherProduct?.title}
                        </span>
                        <Show when={brand.voucherProduct?.discountPercentage}>
                          <div class="flex flex-row items-end">
                            <div class="flex grow flex-col items-start justify-start">
                              <div class="flex grow items-baseline text-f12 text-textNormal">
                                {brand.voucherProduct?.discountPercentage}% off
                              </div>
                            </div>
                          </div>
                        </Show>
                      </div>
                    </div>
                  </div>
                )}
              </For>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </Suspense>
  );
}
